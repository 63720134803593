import { useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import EditItem from "../../common/EditItem";
import {
  createWorkOrder,
  updateWorkOrder,
  getWorkOrder,
  IWorkOrderStatuses,
  getWorkOrderStatuses,
} from "../../services/assetServices";
import ZoneSelector from "../zones/ZoneSelector";
import {
  IconButton,
  Label,
  Pivot,
  PivotItem,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import AssetSelector from "../issues/AssetSelector";
import { useBoolean } from "@fluentui/react-hooks";
import IssueSelector from "./IssueSelector";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";
import WorkOrderComments from "./WorkOrderComments";

const WorkOrder = () => {
  const isSaved = useRef(false);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const [workOrderStatuses, setWorkOrderStatuses] = useState<
    IWorkOrderStatuses[]
  >([]);
  const [workOrderId, setWorkOrderId] = useState<number>();
  const [issueId, setIssueId] = useState(0);
  const [assetId, setAssetId] = useState<number>();
  const [zoneId, setZoneId] = useState<number>();
  const [
    isAssetSelectorOpen,
    { setTrue: showAssetSelector, setFalse: hideAssetSelector },
  ] = useBoolean(false);
  const [
    isIssueSelectorOpen,
    { setTrue: showIssueSelector, setFalse: hideIssueSelector },
  ] = useBoolean(false);

  useEffect(() => {
    const fetchData = async () => {
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        setWorkOrderStatuses(await getWorkOrderStatuses(abortController));
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      };
    };

    fetchData();
  }, []);

  return (
    <>
      <Pivot>
        <PivotItem headerText="Details">
          <Stack tokens={{ childrenGap: 10 }}>
            <Text variant="large">Work Order</Text>
            <ZoneSelector
              zoneId={zoneId}
              disabled={isSaved.current}
              onSelected={(id: number) => setZoneId(id)}
            />
            <Stack horizontal>
              <Label style={labelColumnStyle}>Asset Id</Label>
              <TextField style={valueColumnStyle} value={assetId?.toString()} />
              <IconButton
                iconProps={{ iconName: "Search" }}
                onClick={() => showAssetSelector()}
              />
              <AssetSelector
                isOpen={isAssetSelectorOpen}
                hideAssetSelector={hideAssetSelector}
                onAssetLinkClick={(assetId) => {
                  setAssetId(assetId);
                  hideAssetSelector();
                }}
              />
            </Stack>
            <Stack horizontal>
              <Label style={labelColumnStyle}>Issue Id</Label>
              <TextField style={valueColumnStyle} value={issueId?.toString()} />
              <IconButton
                iconProps={{ iconName: "Search" }}
                onClick={() => showIssueSelector()}
              />
              <IssueSelector
                isOpen={isIssueSelectorOpen}
                hideIssueSelector={hideAssetSelector}
                onIssueLinkClick={(issueId) => {
                  setIssueId(issueId);
                  hideIssueSelector();
                }}
              />
            </Stack>
            <EditItem
              getAction={async (abortController, id) => {
                const workOrder: any = await getWorkOrder(abortController, id);
                setWorkOrderId(id);
                setZoneId(workOrder.zoneId);
                setIssueId(workOrder.issueId);
                setAssetId(workOrder.assetId);
                workOrder.statusId = (workOrder.statusId ?? "").toString();
                return workOrder;
              }}
              newAction={async (abortController, newWorkOrder) => {
                const newWorkOrderResponse = await createWorkOrder(
                  abortController,
                  issueId,
                  {
                    assetId: assetId,
                    zoneId: zoneId,
                    description: newWorkOrder.description,
                    issueTypeId: newWorkOrder.issueTypeId,
                  }
                );
                setSuccessMessage(
                  `WorkOrder with id: ${newWorkOrderResponse.workOrderId} created.`
                );
              }}
              editAction={async (abortController, id, editedWorkOrder) => {
                await updateWorkOrder(abortController, id, {
                  description: editedWorkOrder.description,
                  statusId: Number.parseInt(editedWorkOrder.statusId),
                });
                setSuccessMessage(
                  `WorkOrder with id: ${id} successfully updated.`
                );
              }}
              isSaved={isSaved}
              back={"/workOrders"}
              metadata={{
                fields: [
                  {
                    name: "statusId",
                    fieldType: "Lookup",
                    label: "Status Id",
                    lookupList: "Statuses",
                  },
                  {
                    name: "description",
                    fieldType: "String",
                    label: "Description",
                    rows: 5,
                  },
                ],
                lookups: [
                  {
                    name: "Statuses",
                    values: workOrderStatuses?.map((wos) => ({
                      key: wos.id.toString(),
                      value: wos.name,
                    })),
                  },
                ],
                validations: [],
              }}
            />
          </Stack>
        </PivotItem>
        <PivotItem headerText="Comments">
            <WorkOrderComments workOrderId={workOrderId}/>
        </PivotItem>
      </Pivot>
    </>
  );
};

export default WorkOrder;
