import { Link, Panel, PanelType, SearchBox, DetailsList, SelectionMode } from '@fluentui/react';
import { useState } from 'react'
import { getIssues, IPagedCollection, IIssue } from '../../services/assetServices';

export interface IIssueSelectorProps {
  isOpen: boolean;
  hideIssueSelector: any;
  onIssueLinkClick: (assetId: number) => void;
}

const IssueSelector = (props: IIssueSelectorProps) => {

  const [assets, setIssues] = useState<IIssue[]>([]);

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.hideIssueSelector}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
      headerText="Pick asset for selected issues"
    >
      <SearchBox
        placeholder="Search asset"
        onSearch={(newValue) => {
          const abortController = new AbortController();
          getIssues(abortController, 10, 1, newValue )
            .then((assets: IPagedCollection<IIssue>) => {
              setIssues(assets.items)
            })
        }}
        onClear={() => setIssues([])} />
      <DetailsList
        columns={[
          {
            key: "Id",
            name: "Id",
            fieldName: "id",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: item => (
              <Link key={item.assetId}
                onClick={() => props.onIssueLinkClick(item.id)}
              >
                {item.id}
              </Link >
            )
          },
          {
            key: "Code",
            name: "Code",
            fieldName: "code",
            minWidth: 100,
            maxWidth: 125,
            isResizable: true,
          },
          {
            key: "category",
            name: "Category",
            fieldName: "category",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
          },
          {
            key: "Name",
            name: "Name",
            fieldName: "name",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
          },
        ]}
        compact={true}
        items={assets}
        selectionMode={SelectionMode.none}
      />
    </Panel>
  )
}

export default IssueSelector