import React, { PropsWithChildren, useEffect, useState } from 'react'
import { IMetadata } from '../services/assetServices';
import { IValidationError } from './ValidationHelper';
import OtherInfo from './OtherInfo';
import { useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom } from '../atoms/messageBarAtoms';
import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultButton, IIconProps, PrimaryButton, Stack } from '@fluentui/react';

export interface IEditItemParams {
  metadata: IMetadata;
  isSaved: React.MutableRefObject<boolean>;
  validationErrors?: IValidationError[];
  defaultValues?: string;
  getAction: (abortController: AbortController, id: number) => Promise<any>;
  newAction: (abortController: AbortController, item: any) => void;
  editAction: (abortController: AbortController, id: number, item: any) => void;
  preButtons?: [{ text: string, onClick: () => void, iconProps?: IIconProps }];
  back?: string;
}

const EditItem = (params: PropsWithChildren<IEditItemParams>) => {
  const children = React.Children.toArray(params.children);

  const query = new URLSearchParams(useLocation().search);
  const itemId = Number.parseInt(query.get("id") ?? "");
  const navigate = useNavigate();

  const [otherInfo, setOtherInfo] = useState("{}");
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const fetchData = async () => {
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const data: any = await params.getAction(abortController, itemId);
      setOtherInfo(JSON.stringify(data));
    }
    catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    if (itemId > 0) {
      fetchData();
    } else {
      setOtherInfo(params.defaultValues ?? "{}");
    }
  }, []);

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <OtherInfo
        metadata={params.metadata}
        otherInfo={otherInfo}
        hideTitle={true}
        isSaved={params.isSaved}
        validationErrors={params.validationErrors}
        onOtherInfoChanged={(newValue: string) => setOtherInfo(JSON.stringify(newValue))}
      />
      {children}
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        {params.preButtons?.map(button => (
          <DefaultButton onClick={button.onClick} iconProps={button.iconProps}>{button.text}</DefaultButton>
        ))}
        <PrimaryButton onClick={() => {
          const abortController = new AbortController();
          if (itemId > 0) {
            params.editAction(abortController, itemId, JSON.parse(otherInfo))
          } else {
            params.newAction(abortController, JSON.parse(otherInfo));
          }
        }}>Save</PrimaryButton>
        <DefaultButton onClick={() => navigate(params.back ?? "")}>Cancel</DefaultButton>
      </Stack>
    </Stack>
  )
}

export default EditItem