import { Checkbox, CommandBar, DetailsListLayoutMode, IColumn, ICommandBarItemProps, SelectionMode, ShimmeredDetailsList, Stack, Text } from '@fluentui/react'
import { useBoolean } from "@fluentui/react-hooks";
import { useContext, useEffect, useState } from 'react';
import { appRoles } from '../../authConfig';
import { scrollStackItemStyles } from '../../common/styles/StackStyles';
import { getActions, getPermissions, IUpdatePermission, IUpdatePermissionsRequest, updatePermissions } from '../../services/assetServices';
import AppContext from '../AppContext';
import { useAtomValue, useSetAtom } from 'jotai';
import { errorMessageAtom, isInProgressAtom, successMessageAtom } from "../../atoms/messageBarAtoms";
import { profileDataAtom } from "../../atoms/authAtoms";
import UserOrGroupSelector from "../../common/selectors/UsersOrGroupSelectors";

const Permissions = () => {
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [refreshCount, setRefreshCount] = useState(0);
  const [shimmered, setShimmered] = useState(false);

  const [columns, setColumns] = useState<IColumn[]>();
  const [items, setItems] = useState<any[]>();
  const [changed, setChanged] = useState(false);

  type ItemType = Record<string, string | number | boolean>;

  let originalItems: string | undefined = undefined;
  const fetchData = async () => {
    context.setSelectedTab('Permissions');
    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const actions = await getActions(abortController);
      const permissions = await getPermissions(abortController);

      const columns: IColumn[] = [
        {
          key: 'name',
          fieldName: 'name',
          name: 'User|Group Name',
          minWidth: 120,
        },
        {
          key: 'id',
          fieldName: 'id',
          name: 'User|Group Id',
          minWidth: 110,
          onRender(item, index, column) {
            return (<Stack horizontal grow={true} tokens={{ childrenGap: 5 }}>
              <Text variant="large">{item.id}</Text>
              <Text variant="small">{item.isGroup ? `(Group)` : `(User)`}</Text>
            </Stack>)
          },
        },
      ];
      actions.forEach(action => columns.push(
        {
          key: action.name,
          fieldName: action.name,
          name: action.name,
          minWidth: 125,
          isResizable: true,
          data: action.id,
          onRender(item, index, column) {
            return <Checkbox
              checked={item[column?.fieldName ?? ""]}
              name={`${item['isGroup'] ? 'g' : 'u'}${item['id']}`}
              onChange={(ev, checked) => {
                const name = (ev?.target as any).name;
                const isGroup = name.startsWith('g');
                const id = Number.parseInt(name.replace('u', '').replace('g', ''));
                const columnName = column?.fieldName;
                setItems((prevItems) => {
                  if (!prevItems) {
                    return prevItems;
                  }
                  const newItems = [...prevItems];
                  const row = newItems?.find(item => item.id === id && item.isGroup === isGroup);
                  if (columnName) {
                    row[columnName] = checked;
                  }
                  setChanged(originalItems !== JSON.stringify(newItems));
                  return newItems;
                })
              }}
            />
          },
        }
      ));
      setColumns(columns);
      const result: ItemType[] = [];
      permissions.forEach(perm => {
        const permActionName = actions?.find(action => action.id === perm.actionId)?.name;
        const row = result.find((row: any) => row.isGroup ? row.id === perm.groupId : row.id === perm.userId);
        if (!row) {
          const newRow: ItemType = {};
          newRow['id'] = perm.groupId ?? perm.userId ?? 0;
          newRow['name'] = perm.name;
          newRow['isGroup'] = !!perm.groupId;
          newRow['permId'] = perm.id;
          actions.forEach(action => {
            newRow[action.name] = false;
          });
          if (permActionName) {
            newRow[permActionName] = true;
          }
          result.push(newRow);
        } else {
          if (permActionName) {
            row[permActionName] = true;
          }
        }
      })

      setItems(result);
      if (!originalItems) {
        originalItems = JSON.stringify(result);
      }

      setShimmered(false);
    }
    catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    }
    finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, [refreshCount]);

  const context = useContext(AppContext);
  const profileData = useAtomValue(profileDataAtom);

  const handleSaveClick = () => {
    const payload: IUpdatePermissionsRequest = {
      permissions: items?.map((item) => ({
        userId: (item.isGroup ? undefined : item.id) as number | undefined,
        groupId: (item.isGroup ? item.id : undefined) as number | undefined,
        actions: columns?.filter(column => column.key !== 'id' && column.key !== 'name')
          .filter(column => item[column.key])
          .map(column => (column.data))
      } as IUpdatePermission)) ?? []
    };
    const abortController = new AbortController();

    setIsInProgress(true);
    updatePermissions(abortController, payload)
      .then((data) => {
        if (data.message === "OK") {
          setSuccessMessage("Permission updated successfully.");
          originalItems = JSON.stringify(items);
          setChanged(false);
        } else {
          setErrorMessage(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  }

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: () => showUsersSelector(),
    },
    {
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => { setRefreshCount(refreshCount + 1); },
    },
    {
      key: 'save',
      text: 'Save',
      iconProps: { iconName: 'Save' },
      disabled: !profileData.roles.includes(appRoles.Admin) || !changed,
      onClick: () => handleSaveClick(),
    }
  ];

  const [isUsersSelectorOpen, { setTrue: showUsersSelector, setFalse: hideUsersSelector }] = useBoolean(false);

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={commandBarItems}
          styles={{ root: { width: 550 } }}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
      </Stack>
      <Stack>
        <Stack.Item align="start" styles={scrollStackItemStyles} >
          <ShimmeredDetailsList
            items={items ?? []}
            columns={columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.fixedColumns}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            enableShimmer={shimmered}
          />
        </Stack.Item>
      </Stack>
      <UserOrGroupSelector
        isOpen={isUsersSelectorOpen}
        hideUsersAndGroupsSelector={hideUsersSelector}
        onUserOrGroupSelected={(id: number, isGroup: boolean, username?: string) => {
          if (items) {
            setItems([...items, { id: id, name: username, isGroup: isGroup, email: '' }]);
          }
        }}
      />
    </>
  )
}

export default Permissions;