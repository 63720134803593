import { useContext, useEffect, useRef, useState } from "react";
import {
  DefaultButton,
  PrimaryButton,
  Dropdown,
  FontIcon,
  mergeStyles,
  Label,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { TextField } from "@fluentui/react/lib/TextField";
import { Stack, IStackProps, IStackStyles } from "@fluentui/react/lib/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import AppContext from "../AppContext";
import {
  createAsset,
  editAsset,
  getAssetCategories,
  getAsset,
  getMetadata,
  getZones,
  IAsset,
  IAssetCategory,
  IChatCommand,
  ICreateAssetRequest,
  IMetadata,
  IUpdateAssetRequest,
  IZone,
  IAssetStatuses,
  getAssetStatuses,
  IValidation,
  ICreateAssetResponse,
} from "../../services/assetServices";
import ConfirmDialog from "../../common/ConfirmDialog";
import {
  flattenCategoriesTree,
  flattenZonesTree,
} from "../../common/FlattenHelper";
import {
  labelColumnStyle,
  valueColumnStyle,
} from "../../common/styles/FormsStyles";
import OtherInfo, { cleanOtherInfo } from "../../common/OtherInfo";
import { useAtom, useSetAtom } from "jotai";
import {
  errorMessageAtom,
  isInProgressAtom,
  successMessageAtom,
} from "../../atoms/messageBarAtoms";
import { IValidationError, validate } from "../../common/ValidationHelper";
import {
  chatCommandContentsAtom,
  defaultChatCommand,
} from "../../atoms/chatCommandAtoms";
import AssetCategorySelector from "./AssetCategorySelector";
import ZoneSelector from "../zones/ZoneSelector";

const EditAsset = () => {
  const query = new URLSearchParams(useLocation().search);
  const assetId = Number.parseInt(query.get("id") ?? "");

  const navigate = useNavigate();

  const [categories, setCategories] = useState<IAssetCategory[]>();
  const [zones, setZones] = useState<IZone[]>();
  const [asset, setAsset] = useState<IAsset>();
  const [assetStatuses, setAssetStatuses] = useState<IAssetStatuses[]>([]);

  const savedAsset = useRef<IAsset>();
  const isSaved = useRef(false);

  const [metadata, setMetadata] = useState<IMetadata>();
  const [otherInfo, setOtherInfo] = useState({});

  const context = useContext(AppContext);
  const setSuccessMessage = useSetAtom(successMessageAtom);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const [chatCommandContents, setChatCommandContents] = useAtom(
    chatCommandContentsAtom
  );
  const [errors, setErrors] = useState<IValidationError[]>();

  const fetchData = async () => {
    context.setSelectedTab("Assets");

    const abortController = new AbortController();
    setIsInProgress(true);
    try {
      const metadata = await getMetadata(abortController, "Asset");
      setMetadata(metadata);

      const data = await getZones(abortController);
      const flattenList: IAssetCategory[] = [];
      flattenZonesTree(data, flattenList, "");

      setZones(flattenList);
      const categoriesData = await getAssetCategories(abortController);
      const flattenCategoriesList: IAssetCategory[] = [];
      flattenCategoriesTree(categoriesData, flattenCategoriesList, "");

      setCategories(flattenCategoriesList);

      const assetStatuses: IAssetStatuses[] = await getAssetStatuses(
        abortController
      );
      setAssetStatuses(assetStatuses);

      if (assetId) {
        const itemDetail = await getAsset(abortController, assetId);
        setAsset({
          code: itemDetail.asset.code,
          id: itemDetail.asset.id,
          name: itemDetail.asset.name,
          uuid: itemDetail.asset.uuid,
          zoneId: itemDetail.asset.zoneId,
          statusId: itemDetail.asset.statusId,
          category: "",
          categoryId: itemDetail.asset.categoryId,
        });
        setOtherInfo(JSON.parse(itemDetail.asset.otherInfo ?? "{}"));
        savedAsset.current = { ...itemDetail.asset };
      }
      const command = chatCommandContents;
      if (command.action === "add" && command.entityType === "asset") {
        const otherInfo: any = {};
        metadata?.fields.forEach((f) => {
          const fieldName = f.name;
          if (command.hasOwnProperty(fieldName)) {
            const valueFromCommand = command[
              fieldName as keyof IChatCommand
            ] as string;
            otherInfo[fieldName] = valueFromCommand;
          } else if (command.others.hasOwnProperty(fieldName)) {
            const valueFromCommand = command.others[
              fieldName as keyof IChatCommand
            ] as string;
            otherInfo[fieldName] = valueFromCommand;
          }
        });
        const asset = {
          name: chatCommandContents.name ?? "",
          code: chatCommandContents.code ?? "",
          id: 0,
          uuid: "",
          zoneId: 0,
          statusId: 0,
          category: "",
          categoryId: 0,
        };
        setAsset(asset);
        setOtherInfo(otherInfo);
        setChatCommandContents(defaultChatCommand);
      } else if (command.action === "edit" && command.entityType === "asset") {
        const asset = await getAsset(abortController, undefined, command.code);
        asset.asset.name = chatCommandContents.name ?? asset.asset.name;
        const otherInfo: any = JSON.parse(asset.asset.otherInfo ?? "{}");
        metadata?.fields.forEach((f) => {
          const fieldName = f.name;
          if (command.hasOwnProperty(fieldName)) {
            const valueFromCommand = command[
              fieldName as keyof IChatCommand
            ] as string;
            otherInfo[fieldName] = valueFromCommand
              ? valueFromCommand
              : otherInfo[fieldName];
          } else if (command.others.hasOwnProperty(fieldName)) {
            const valueFromCommand = command.others[
              fieldName as keyof IChatCommand
            ] as string;
            otherInfo[fieldName] = valueFromCommand
              ? valueFromCommand
              : otherInfo[fieldName];
          }
        });
        asset.asset.otherInfo = JSON.stringify(otherInfo);
        setAsset(asset.asset);
        setOtherInfo(JSON.parse(asset.asset.otherInfo));
        setChatCommandContents(defaultChatCommand);
      }
    } catch (error: any) {
      console.error("Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }

    const submitData = async () => {
      const errs: IValidationError[] = [];
      const validations: IValidation[] = [];
      if (metadata) {
        validations.push(...metadata.validations);
      }
      validations.push({ field: "code", rules: ["NE"] });
      validations.push({ field: "name", rules: ["NE"] });
      const metadataErrs = validate(
        { ...asset, otherInfo: otherInfo },
        validations,
        metadata?.fields
      );
      errs.push(...metadataErrs);
      // if (!asset?.categoryId) {
      //   errs.push({ fieldName: 'category', message: 'Asset category should be selected' });
      // }
      setErrors(errs);
      const otherInfoStr = cleanOtherInfo(otherInfo, metadata?.fields);

      if (errs.length > 0) {
        return;
      }
      setIsInProgress(true);
      const abortController = new AbortController();

      try {
        if (asset?.id) {
          const updateAssetRequest: IUpdateAssetRequest = {
            code: asset?.code,
            name: asset?.name,
            categoryId: asset?.categoryId,
            zoneId: asset?.zoneId,
            statusId: asset?.statusId,
            changedate: asset?.changeDate,
            otherInfo: otherInfoStr,
          };
          const data: number = await editAsset(
            abortController,
            asset?.id,
            updateAssetRequest
          );
          setSuccessMessage("Item successfully updated");
          savedAsset.current = {
            id: data,
            uuid: savedAsset.current?.uuid ?? "",
            category: savedAsset.current?.category ?? "",
            code: updateAssetRequest.code ?? "",
            name: updateAssetRequest.name ?? "",
            categoryId: Number(updateAssetRequest.categoryId) ?? "",
            zoneId: Number(updateAssetRequest.zoneId) ?? "",
            statusId: updateAssetRequest.statusId,
          };
          isSaved.current = true;
        } else {
          const createAssetRequest: ICreateAssetRequest = {
            uuid: uuid(),
            code: asset?.code ?? "",
            name: asset?.name ?? "",
            categoryId: asset?.categoryId,
            zoneId: asset?.zoneId,
            statusId: asset?.statusId,
            changedate: new Date().toISOString(),
            otherInfo: otherInfoStr,
          };
          const data: ICreateAssetResponse = await createAsset(
            abortController,
            createAssetRequest
          );
          if (data.created) {
            setSuccessMessage("Asset created.");
            isSaved.current = true;
          } else {
            setErrorMessage(
              `Asset with this code already exists, Asset code:${asset?.code}`
            );
            isSaved.current = false;
          }
        }
      } catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      } finally {
        setIsInProgress(false);
      }
    };

    submitData();
  };

  const stackTokens = { childrenGap: 50 };
  const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 600 } },
  };

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const handleChange = (fieldName: string, value?: string) => {
    setAsset((prev: any) => {
      const newAsset = { ...prev };
      newAsset[fieldName] = value;
      return newAsset;
    });
  };
  const statusIconClass = mergeStyles({
    fontSize: 12,
    paddingRight: 5,
  });

  return (
    <div className="App">
      <form onSubmit={onSubmit}>
        <div>
          <div className="form-group">
            <Stack tokens={stackTokens} styles={stackStyles}>
              <Stack {...columnProps} horizontal wrap>
                <Stack horizontal>
                  <Label style={labelColumnStyle}>Code</Label>
                  <TextField
                    style={{ width: valueColumnStyle.width - 2 }}
                    value={asset?.code}
                    onChange={(_, newValue) => handleChange("code", newValue)}
                    readOnly={isSaved.current}
                    errorMessage={
                      errors?.find((e) => e.fieldName === "code")?.message
                    }
                  />
                </Stack>

                <Stack horizontal>
                  <Label style={labelColumnStyle}>Name</Label>
                  <TextField
                    style={{ width: valueColumnStyle.width - 2 }}
                    value={asset?.name}
                    onChange={(_, newValue) => handleChange("name", newValue)}
                    readOnly={isSaved.current}
                    errorMessage={
                      errors?.find((e) => e.fieldName === "name")?.message
                    }
                  />
                </Stack>
                <AssetCategorySelector
                  categoryId={asset?.categoryId}
                  disabled={isSaved.current}
                  onSelected={(id: number) => {
                    setAsset((prevItem: any) => ({
                      ...prevItem,
                      categoryId: id,
                    }));
                  }}
                  errorMessage={
                    errors?.find((e) => e.fieldName === "category")?.message
                  }
                />
                <ZoneSelector
                  zoneId={asset?.zoneId}
                  disabled={isSaved.current}
                  onSelected={(id: number) => {
                    setAsset((prevItem: any) => ({ ...prevItem, zoneId: id }));
                  }}
                />

                <Stack horizontal>
                  <Label style={labelColumnStyle}>Status</Label>
                  <Dropdown
                    style={valueColumnStyle}
                    options={
                      assetStatuses?.map((status) => ({
                        key: status.id ?? "",
                        text: status.name ?? "",
                        data: status,
                      })) ?? []
                    }
                    selectedKey={asset?.statusId}
                    disabled={isSaved.current}
                    onRenderTitle={(options) => {
                      if (!options) {
                        return <></>;
                      }
                      const option = options[0];
                      return options ? (
                        <span>{option.text}</span>
                      ) : (
                        <span></span>
                      );
                    }}
                    onRenderOption={(option) =>
                      option && option.text ? (
                        <Stack horizontal>
                          <FontIcon
                            iconName={option.data.icon}
                            className={statusIconClass}
                          />
                          <span>{option.text}</span>
                        </Stack>
                      ) : (
                        <div></div>
                      )
                    }
                    onChange={(e, o) => {
                      setAsset((prevItem: any) => ({
                        ...prevItem,
                        statusId: o?.key,
                      }));
                    }}
                  />
                </Stack>
              </Stack>

              <Stack {...columnProps} horizontal wrap>
                {metadata ? (
                  <OtherInfo
                    metadata={metadata}
                    otherInfo={JSON.stringify(otherInfo)}
                    isSaved={isSaved}
                    onOtherInfoChanged={(newValue: string) =>
                      setOtherInfo(newValue)
                    }
                    validationErrors={errors}
                  />
                ) : (
                  <></>
                )}
                <Stack.Item align="center">
                  <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton type="submit" disabled={isSaved.current}>
                      Save
                    </PrimaryButton>
                    <DefaultButton
                      onClick={() => {
                        if (
                          savedAsset.current?.code !== asset?.code ||
                          savedAsset.current?.name !== asset?.name ||
                          savedAsset.current?.categoryId !== asset?.categoryId
                        ) {
                          showModal();
                        } else {
                          navigate("/assets");
                        }
                      }}
                    >
                      Cancel
                    </DefaultButton>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack>

            <ConfirmDialog
              isModalOpen={isModalOpen}
              hideModal={hideModal}
              message="All your unsaved changes would be lost."
              onYesClick={() => navigate("/assets")}
            />
          </div>
        </div>
      </form>{" "}
    </div>
  );
};

export default EditAsset;
