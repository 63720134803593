import {
  ChoiceGroup,
  CommandBar,
  DefaultButton,
  DetailsListLayoutMode,
  FontIcon,
  ICommandBarItemProps,
  IStackTokens,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
  Separator,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { useEffect, useState } from "react";
import {
  getImportAssets,
  IImportedAsset,
  IImportResult,
  ILastImport,
  importAssets,
  previewImportAssets,
} from "../../services/assetServices";
import { downloadFile } from '../../common/DownloadFile';
import ImportLogs from "./ImportLogs";
import { useSetAtom } from "jotai";
import { isInProgressAtom } from "../../atoms/messageBarAtoms";
import { RenderIf } from "../../libs";
import { FileSelector } from "../../common/FileSelector";

const ImportAsset = () => {
  const importDialogContentProps = {
    type: DialogType.normal,
    title: "Initiating import",
    closeButtonAriaLabel: "Close",
  };
  const setIsInProgress = useSetAtom(isInProgressAtom);

  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [isImportFileDialogOpen, setIsImportFileDialogOpen] = useState(false);

  const [message, setMessage] = useState("");

  const [importedAssets, setImportedAssets] = useState<IImportedAsset[]>([]);

  const [shimmered, setShimmered] = useState(false);
  const [showImportedDataList, setShowImportedDataList] = useState(false);
  const [logFile, setLogFile] = useState("");

  const [importData, setImportData] = useState({
    lastImport: { lastImportedDateTime: "", lastImportedId: "", createdAt: "" },
    latestImportedAsset: { code: "", changeDate: "" },
    latestAssetInSourceDate: "",
    latestAssetInSourceNum: "",
  });

  useEffect(() => {
    const abortController = new AbortController();

    setIsInProgress(true);
    setMessage("Fetching import history");
    getImportAssets(abortController)
      .then((data: ILastImport) => {
        setMessage("");
        setImportData(data);
        setIsInProgress(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage(error.Message);
        setIsInProgress(false);
      });

    return () => {
      abortController.abort();
    }
  }, [importData?.latestAssetInSourceDate]);

  const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
  };

  const handlePreviewImportClick = () => {
    setShowImportedDataList(true);
    setShimmered(true);
    const abortController = new AbortController();

    previewImportAssets(abortController)
      .then((data: IImportedAsset[]) => {
        const message = `${data.length} assets to be imported`;
        setMessage(message);
        setImportedAssets(data);
        setShimmered(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowImportedDataList(false);
        setMessage(error.Message);
      });
  };

  const handleImportClick = () => {
    setShowImportedDataList(true);
    setShimmered(true);
    const abortController = new AbortController();

    importAssets(abortController, clearChoice, seed)
      .then((data: IImportResult) => {
        const message = `${data.importedItems.length} items imported`;
        setMessage(message);
        setImportedAssets(data.importedItems);
        setLogFile(data.logFilePath);
        setShimmered(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowImportedDataList(false);
        setMessage(error.Message);
      });
    setIsImportDialogOpen(false);
  };

  const handleImportFileClick = () => {
    setShowImportedDataList(true);
    setShimmered(true);
    const abortController = new AbortController();

    importAssets(abortController, clearChoice, seed, csvContent)
      .then((data: IImportResult) => {
        const message = `${data.importedItems.length} items imported`;
        setMessage(message);
        setImportedAssets(data.importedItems);
        setLogFile(data.logFilePath);
        setShimmered(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowImportedDataList(false);
        setMessage(error.Message);
      });
    setIsImportFileDialogOpen(false);
  };

  const columns = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      minWidth: 25,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "Code",
      name: "Code",
      fieldName: "code",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "Date",
      name: "Date",
      fieldName: "changedDate",
      minWidth: 150,
      maxWidth: 175,
      isResizable: true,
    },
  ];

  const [clearChoice, setClearChoice] = useState(false);
  const [seed, setSeed] = useState(1);

  const optionRootClass = mergeStyles({
    display: "flex",
    alignItems: "center",
    gap: "5px",
  });
  const warningIconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: "0 10px",
    color: "red",
  });
  const classNames = mergeStyleSets({
    dataListContainer: {
      height: 500,
      overflow: 'auto',
    },
  });

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'preview',
      iconProps: { iconName: 'Blur' },
      text: 'Preview import',
      onClick: () => handlePreviewImportClick(),
    },
    {
      key: 'start',
      iconProps: { iconName: 'Play' },
      text: 'Start import',
      subMenuProps: {
        items: [
          {
            key: 'startFromApi',
            text: 'Import from Api',
            iconProps: { iconName: 'AzureAPIManagement' },
            onClick: () => setIsImportDialogOpen(true),
          },
          {
            key: 'startFromFile',
            text: 'Import from CSV',
            iconProps: { iconName: 'ExcelDocument' },
            onClick: () => setIsImportFileDialogOpen(true),
          },
        ]
      }
    },
    {
      key: 'logFile',
      text: 'Show Log File',
      iconProps: { iconName: 'DownloadDocument' },
      disabled: !logFile,
      onClick: () => {
        try {
          setIsInProgress(true);
          downloadFile(logFile);
        } finally {
          setIsInProgress(false);
        }
      }
    }
  ]

  const [csvContent, setCsvContent] = useState("");
  const [firstLine, setFirstLine] = useState<string>();
  const [csvValidation, setCsvValidation] = useState<string>();

  const correctColumns: string = "Code,Name,AssetChangeDate,Zone,Category";
  const findFirstDiff = (input: string): number => {
    for (let index = 0; index < input.length; index++) {
      if (correctColumns[index] !== input[index]) {
        return index;
      }
    }
    return 0;
  }
  return (
    <>
      <Dialog
        hidden={!isImportDialogOpen}
        onDismiss={() => setIsImportDialogOpen(false)}
        dialogContentProps={importDialogContentProps}
      >
        <Stack horizontal>
          <ChoiceGroup
            label="Clear existing assets"
            defaultSelectedKey="no"
            options={[
              {
                key: "yes",
                text: "Yes",
                styles: { root: { border: "1px solid red" } },
                onRenderField: (props, render) => {
                  return (
                    <div className={optionRootClass}>
                      {render!(props)}
                      <FontIcon
                        aria-label="Train"
                        iconName="WarningSolid"
                        className={warningIconClass}
                      />
                    </div>
                  );
                },
              },
              { key: "no", text: "No" },
            ]}
            onChange={(ev, option) => {
              setClearChoice(option?.key === "yes");
            }}
          />
        </Stack>
        <TextField
          label="Starting number"
          disabled={!clearChoice}
          onChange={(ev, newValue) => {
            if (newValue) {
              setSeed(parseInt(newValue));
            }
          }}
        />

        <DialogFooter>
          <PrimaryButton onClick={() => handleImportClick()} text="Yes" />
          <DefaultButton
            onClick={() => setIsImportDialogOpen(false)}
            text="No"
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={!isImportFileDialogOpen}
        onDismiss={() => setIsImportFileDialogOpen(false)}
        dialogContentProps={importDialogContentProps}
      >
        <Stack>
          <Text variant="medium">Please select a CSV file with following columns:</Text>
          <span style={{ borderStyle: 'solid', borderColor: 'black', borderBottomStyle: 'none', boxShadow: '2px 5px 2px gray', height: 55 }}>
            <Text variant='xSmall' style={{ fontFamily: 'monospace' }}>{correctColumns},...</Text>
          </span>
          <br />
          <Text variant="small">* Company specific fields can follow these columns.</Text>
          <br />
          <FileSelector value={''} uploadImage={false} isTextFile={true} purpose='ImportAsset' onFileSelected={(name, content) => {
            const contentStr = content as string;
            const lines = contentStr.split('\n');
            if (lines.length <= 1) {
              setCsvValidation("Empty file or not correct line break");
              return;
            }
            const firstLine = lines[0];
            setCsvContent(contentStr);
            if (firstLine?.replace(' ', '').toLowerCase().startsWith(correctColumns.toLowerCase())) {
              setCsvValidation('ok: Headers match');
            } else {
              setCsvValidation(`Error. The columns doesn't match`);
            }
            setFirstLine(firstLine);
          }} />
          <RenderIf condition={!!csvValidation?.startsWith('Error')}>
            <span style={{ borderStyle: 'solid', borderColor: 'red', borderBottomStyle: 'none', boxShadow: '2px 5px 2px gray', height: 55 }}>
              <p style={{ fontFamily: 'monospace', fontSize: 10, color: 'red' }}>{firstLine}</p>
              <p style={{ fontFamily: 'monospace', fontSize: 10, color: 'red' }}>{`${'-'.repeat(findFirstDiff(firstLine ?? ''))}^`}</p>
            </span>
          </RenderIf>
          <RenderIf condition={csvValidation !== undefined}>
            <Text variant="medium" styles={{ root: { color: csvValidation?.startsWith('ok') ? 'green' : 'red' } }}>{csvValidation}</Text>
          </RenderIf>
        </Stack>
        <Stack horizontal>
          <ChoiceGroup
            label="Clear existing assets"
            defaultSelectedKey="no"
            options={[
              {
                key: "yes",
                text: "Yes",
                styles: { root: { color: "red" } },
                onRenderField: (props, render) => {
                  return (
                    <div className={optionRootClass}>
                      {render!(props)}
                      <FontIcon
                        aria-label="Train"
                        iconName="WarningSolid"
                        className={warningIconClass}
                      />
                    </div>
                  );
                },
              },
              { key: "no", text: "No" },
            ]}
            onChange={(ev, option) => {
              setClearChoice(option?.key === "yes");
            }}
          />
        </Stack>
        <TextField
          label="Starting number"
          disabled={!clearChoice}
          onChange={(ev, newValue) => {
            if (newValue) {
              setSeed(parseInt(newValue));
            }
          }}
        />

        <DialogFooter>
          <PrimaryButton disabled={!csvValidation?.startsWith("ok")} onClick={() => handleImportFileClick()} text="Import" />
          <DefaultButton
            onClick={() => {
              setIsImportFileDialogOpen(false);
              setCsvValidation(undefined);
            }}
            text="No"
          />
        </DialogFooter>
      </Dialog>

      <Stack tokens={stackTokens}>
        {message ? <div>{message}</div> : ""}
        <Separator alignContent='start'>
          <Text variant="large">Last import</Text>
        </Separator>
        <RenderIf condition={!!importData}>
          <Stack horizontal>
            <Text>Import date: </Text>
            <Text>{importData ? (new Date(importData.lastImport?.createdAt + 'Z').toLocaleString()) : ""}</Text>
          </Stack>
          <Stack horizontal>
            <Text>Code of latest imported asset: </Text>
            <Text>{importData ? importData.latestImportedAsset?.code : ""}</Text>
          </Stack>
          <Stack horizontal>
            <Text>Date of latest imported asset: </Text>
            <Text>{importData ? new Date(importData.latestImportedAsset?.changeDate + 'Z').toLocaleString() : ""}</Text>
          </Stack>
        </RenderIf>
        {!importData ?
          <Text>No previous import</Text>
          : <>
            <Separator alignContent='start'>
              <Text variant="large">Source</Text>
            </Separator>
            <Stack horizontal>
              <Text>Latest asset date: </Text>
              <Text>{importData ? new Date(importData.latestAssetInSourceDate).toLocaleString() : ''}</Text>
            </Stack>
            <Stack horizontal>
              <Text>Latest asset code: </Text>
              <Text>{importData ? importData.latestAssetInSourceNum : ''}</Text>
            </Stack>
          </>}
        <Separator alignContent='start'>
          <Text variant="large">Import logs</Text>
        </Separator>
        <CommandBar items={commandBarItems}></CommandBar>
        <ImportLogs></ImportLogs>
        {showImportedDataList ? (
          <div className={classNames.dataListContainer} >
            <ShimmeredDetailsList
              items={importedAssets}
              columns={columns}
              setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            />
          </div>
        ) : (
          ""
        )}
      </Stack>
    </>
  );
};

export default ImportAsset;
